import { useRef } from 'react';
import { Form } from 'react-final-form';
import styled from 'styled-components';
import useI18n from '../../../../../lib/use_i18n';
import { useIsVisible } from '../../../../../lib/use_is_visible';
import useVisibility from '../../../../../lib/use_visibility';
import Icon from '../../../icon';
import InlineConfirmation from '../../../inline_confirmation';
import { MODAL_WRAPPER_ID } from '../../../modal/modal';
import { BorderlessButton } from '../../../styles/button';
import { Flex } from '../../../styles/flex';
import { Field } from '../../field';
import { RenameInputWrapper, StyledImageRow } from '../styles';

export const ImageRow = ({
  onClick,
  handleDelete,
  generateHandleUpdate,
  image,
  active,
  listRef,
  onDoubleClick,
}) => {
  const rowRef = useRef();
  const isVisible = useIsVisible(rowRef, listRef);
  const {
    shown: isRenaming,
    show: showNameInput,
    hide: hideNameInput,
  } = useVisibility(rowRef, false, true, `#${MODAL_WRAPPER_ID}`);

  const handleUpdate = generateHandleUpdate(hideNameInput);

  return (
    <StyledImageRow
      ref={rowRef}
      role="listitem"
      active={!isRenaming && active}
      isRenaming={isRenaming}
      onClick={() => onClick(image)}
      onDoubleClick={!isRenaming ? () => onDoubleClick(image) : undefined}
    >
      {isVisible ? (
        isRenaming ? (
          <RenameInput image={image} handleSubmit={handleUpdate} />
        ) : (
          <Row
            handleDelete={handleDelete}
            image={image}
            showNameInput={showNameInput}
          />
        )
      ) : null}
    </StyledImageRow>
  );
};

const Row = ({ image, showNameInput, handleDelete }) => {
  const { translate } = useI18n('image_uploader.select_image');
  return (
    <>
      <Flex overflow="hidden" gap="8px" alignItems="center">
        <Thumbnail src={image.url} alt={image.name} />
        <ImageName>{image.name}</ImageName>
      </Flex>
      <Flex>
        <Button
          data-balloon-pos="left"
          aria-label={translate('.edit_tooltip')}
          onClick={() => showNameInput()}
        >
          <Icon name="edit" size={24} />
        </Button>
        <InlineConfirmation
          confirmClass="btn-danger"
          onConfirmation={() => handleDelete(image)}
        >
          {(deleteCallback) => (
            <Button
              data-balloon-pos="left"
              aria-label={translate('app.actions.delete')}
              onClick={(e) => {
                e.stopPropagation();
                deleteCallback();
              }}
            >
              <Icon name="delete" size={24} />
            </Button>
          )}
        </InlineConfirmation>
      </Flex>
    </>
  );
};

const RenameInput = ({ image, handleSubmit }) => {
  const { translate } = useI18n();
  return (
    <Form onSubmit={handleSubmit} initialValues={image}>
      {({ handleSubmit }) => (
        <form onSubmit={handleSubmit}>
          <RenameInputWrapper
            alignItems="center"
            width="100%"
            justifyContent="space-between"
          >
            <Field name="name">
              {({ input }) => (
                <input
                  autoFocus={true} // eslint-disable-line
                  {...input}
                  onFocus={(e) => e.target.select()}
                />
              )}
            </Field>
            <BorderlessButton type="submit">
              {translate('app.actions.save')}
            </BorderlessButton>
          </RenameInputWrapper>
        </form>
      )}
    </Form>
  );
};

const ImageName = styled.span`
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

const Button = styled.button`
  background: transparent;
  border: none;
  display: flex;
  align-items: center;
`;

const Thumbnail = styled.img`
  max-height: 30px;
  min-width: 40px;
  width: 40px;
`;
