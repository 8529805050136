import React, { useState } from 'react';
import PropTypes from 'prop-types';
import DatePicker, { registerLocale } from 'react-datepicker';
import de from 'date-fns/locale/de';

import { InputWrapper } from './input_wrapper';
import styled from 'styled-components';
import useI18n from '../../../lib/use_i18n';
import BalloonIcon from '../balloon_icon';
import { Field } from './field';
import { getError, objToISODate, stringToDate } from './utils';
import 'react-datepicker/dist/react-datepicker.css';
import { lightBlue, placeholderGray } from '../styles/variables';

registerLocale('de', de);

const DateInputWrapper = styled.label`
  width: 100%;
  display: flex;
  color: #666;
  .react-datepicker__navigation-icon::before {
    top: 16px;
  }
  input {
    height: 39px;
    width: 100%;
    &:focus-visible {
      outline: none;
    }
  }
  .react-datepicker__close-icon::after {
    color: #666;
    background: transparent;
  }
  .react-datepicker-wrapper {
    width: 100%;
  }
`;

const IconWrapper = styled.div`
  display: flex;
  align-items: center;
  & > * {
    height: 24px;
  }
  .material-symbols-outlined {
    cursor: pointer;
    color: ${placeholderGray};
  }
  .material-symbols-outlined:hover {
    color: ${lightBlue};
  }
`;

const DEFAULT_FORMAT = 'P'; // takes default format from locale

const FORMAT = {
  de: 'd.M.yyyy', // the default one would be "dd.MM.yyyy"
};

export const DateInput = ({
  errorText,
  hint,
  hintId,
  format,
  label,
  labelProps,
  name,
  required,
  value,
  initialValue,
  minDate,
  maxDate,
  ...props
}) => {
  const { locale } = useI18n('date_input');
  const dateFormat = format || FORMAT[locale] || DEFAULT_FORMAT;

  // we have to account for both controlled and uncontrolled inputs,
  // so if the onChange is provided, the value should be provided too,
  // otherwise we let the component handle the value change.
  // react-final-form uses controlled while rails use uncontrolled version.
  // we should refactor once we migrate whole form to react to remove
  // initialValue and localValue
  const [localValue, setLocalValue] = useState(stringToDate(initialValue));
  const handleOnChange = (date) => {
    props.onChange && props.onChange(date);
    setLocalValue(date);
  };
  return (
    <InputWrapper
      name={name}
      errorText={errorText}
      labelProps={labelProps || { label, required }}
      hint={hint}
      hintId={hintId}
    >
      <DateInputWrapper>
        <DatePicker
          {...props}
          name={name}
          id={name}
          minDate={minDate}
          maxDate={maxDate}
          locale={locale}
          selected={props.onChange ? value : localValue}
          dateFormat={dateFormat}
          onChange={handleOnChange}
          autoComplete="off"
        />
        <IconWrapper>
          <BalloonIcon large={true} icon={'today'} />
        </IconWrapper>
      </DateInputWrapper>
    </InputWrapper>
  );
};

DateInput.propTypes = {
  errorText: PropTypes.string,
  format: PropTypes.string,
  hint: PropTypes.string,
  hintId: PropTypes.string,
  initialValue: PropTypes.string, //received from rails d.M.yyyy
  label: PropTypes.string,
  name: PropTypes.string.isRequired,
  required: PropTypes.bool,
  value: PropTypes.instanceOf(Date),
};

export const FormDateInput = ({
  dateFormat,
  hint,
  hintId,
  label,
  labelProps,
  maxDate,
  minDate,
  name,
  required,
  ...fieldProps
}) => {
  return (
    <Field
      parse={objToISODate}
      format={(dateString) => (dateString ? new Date(dateString) : null)}
      name={name}
      required={required}
      {...fieldProps}
    >
      {({ input, meta }) => (
        <DateInput
          {...input}
          format={dateFormat}
          errorText={getError(meta)}
          hint={hint}
          hintId={hintId}
          labelProps={{ label, required, ...labelProps }}
          maxDate={maxDate}
          minDate={minDate}
          name={name}
          onChange={(value) => {
            fieldProps?.onChange && fieldProps?.onChange(value);
            input.onChange(value);
          }}
          required={required}
          value={input.value || null}
        />
      )}
    </Field>
  );
};

FormDateInput.propTypes = {
  ...Field.propTypes,
  dateFormat: PropTypes.string,
  name: PropTypes.string.isRequired,
  label: PropTypes.string,
  labelProps: PropTypes.object,
  required: PropTypes.bool,
  minDate: PropTypes.instanceOf(Date),
  maxDate: PropTypes.instanceOf(Date),
};
