/* eslint-disable no-unused-vars */

import styled from 'styled-components';
import {
  colorText,
  fontSizeS,
  fontSizeXS,
  lightGray,
  lineheightSmall,
  placeholderGray,
} from './variables';

export const SearchBox = styled.div`
  display: inline-block;
  flex-grow: 1;
  max-width: 350px;
`;

export const Label = styled.label`
  display: flex;
  color: ${lightGray};
  font-size: ${fontSizeXS};

  border: 1px solid #eee;
  border-radius: 3px;
  padding: 0px !important;
  margin: 0;
  align-items: center;
  gap: 8px;

  &:focus-within {
    border-color: #1c2c3a;
  }

  .material-symbols-outlined {
    margin-left: 8px;
  }

  .material-symbols-outlined:last-child {
    margin: 0 5px 0 0;
  }
`;

export const Input = styled.input`
  flex-grow: 1;
  height: 36px;
  outline: none;
  box-shadow: none !important;

  && {
    font-size: ${fontSizeS};
  }

  line-height: ${lineheightSmall};
  padding: 12px 0;
  color: ${colorText};

  &::placeholder {
    color: ${placeholderGray};
    opacity: 1;
  }
`;
