import styled from 'styled-components';

import useI18n from '../../../../../lib/use_i18n';
import SearchTerm from '../../../search_term';
import { BorderlessButton } from '../../../styles/button';
import { ButtonsWrapper, Flex } from '../../../styles/flex';
import { Span } from '../../../styles/text';
import { fontSizeXS, gray } from '../../../styles/variables';
import { Preview } from './preview';
import { List } from './list';
import { useGenerateSelectImageValues } from './use_generate_select_image_values';

export const SelectImage = ({
  close,
  handleDelete,
  handleRename,
  handleSelect,
  setActiveTab,
  selectedImageUrl,
  fetchImages,
  recommendedDimensions,
}) => {
  const { translate } = useI18n('image_uploader.select_image');
  const {
    deleteImage,
    generateHandleUpdate,
    totalCount,
    items,
    search,
    selectedImage,
    setSelectedImage,
  } = useGenerateSelectImageValues({
    handleDelete,
    handleRename,
    selectedImageUrl,
    fetchImages,
  });
  return totalCount ? (
    <Flex flexGrow="1" gap={'16px'} height={'100%'} flexDirection="column">
      <SearchTerm
        for="image_uploader.select_image"
        id="images-input-search"
        searchTerm={search.state}
        onChange={search.setState}
      />
      <ListAndPreviewWrapper>
        <List
          close={close}
          deleteImage={deleteImage}
          generateHandleUpdate={generateHandleUpdate}
          handleSelect={handleSelect}
          items={items}
          selectedImage={selectedImage}
          setSelectedImage={setSelectedImage}
          totalCount={totalCount}
        />
        <Preview
          recommendedDimensions={recommendedDimensions}
          selectedImage={selectedImage}
        />
      </ListAndPreviewWrapper>
      <ButtonsWrapper marginTop="auto">
        <button onClick={close} className="btn btn-default">
          {translate('app.actions.cancel')}
        </button>
        <button
          onClick={() => {
            handleSelect(selectedImage);
            close();
          }}
          className="btn btn-primary"
        >
          {translate('app.actions.select')}
        </button>
      </ButtonsWrapper>
    </Flex>
  ) : (
    <NoImagesWrapper>
      <Span color={gray}>{translate('.no_images')}</Span>
      <BorderlessButton onClick={() => setActiveTab('upload')}>
        {translate('.upload_image')}
      </BorderlessButton>
    </NoImagesWrapper>
  );
};

const NoImagesWrapper = styled.div`
  flex-grow: 1;
  height: 100%;
  * {
    font-size: ${fontSizeXS};
  }
`;

const ListAndPreviewWrapper = styled.div`
  flex-grow: 1;
  overflow: hidden;
  gap: 8px;
  height: 100%;
  display: flex;
  & > div {
    width: 50%;
  }
`;
