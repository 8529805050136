import PropTypes from 'prop-types';
import { ChromePicker } from 'react-color';
import styled from 'styled-components';
import useVisibility from '../../../lib/use_visibility';
import { Field } from './field';
import { InputWrapper } from './input_wrapper';
import { getError } from './utils';

const PreviewColor = styled.div`
  width: 20px;
  height: 20px;
  position: relative;
  border: 1px solid #eed;
  border-radius: 3px;
  margin-top: -26px;
  float: right;
`;

const StyledColorPicker = styled.div`
  position: absolute;
  z-index: 2;
`;

const Overlay = styled.div`
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
`;
export const ColorPicker = ({ name, hint, label, labelProps, hintId }) => {
  const { show, shown, hide } = useVisibility();
  return (
    <Field name={name}>
      {({ input, meta }) => (
        <InputWrapper
          name={input.name}
          errorText={getError(meta)}
          labelProps={{ label: label, ...labelProps }}
          hint={hint}
          hintId={hintId}
        >
          <input
            {...input}
            id={name}
            className="form-control"
            autoComplete={'off'}
            onFocus={show}
          />
          <PreviewColor style={{ backgroundColor: input.value }} />
          {shown && (
            <StyledColorPicker role="insertion">
              <Overlay onClick={hide} role="banner" />
              <ChromePicker
                color={input.value || ''}
                onChange={(e) => input.onChange(e.hex)}
              />
            </StyledColorPicker>
          )}
        </InputWrapper>
      )}
    </Field>
  );
};

ColorPicker.propTypes = {
  name: PropTypes.string.isRequired,
  hint: PropTypes.string,
  label: PropTypes.string,
  labelProps: PropTypes.object,
  hintId: PropTypes.string,
};
