import { useState, useEffect, useCallback } from 'react';

/**
 * Custom React hook for managing data fetching with query parameters.
 *
 * Params:
 *   queryKey: key used to identify the query.
 *   query: function that performs the data fetching. It should accept query parameters
 *          as input and return a Promise that resolves to the fetched data.
 *   params: initial query parameters.
 *   immediately: flag indicating whether to fetch data immediately
 *                upon initialization or params chnage. Setting data to false would
 *                require fetchData to be called manualy in order to
 *                fetch the data from the query callback (default: true)
 *
 * Returns: {
 *   data: a response data from the query,
 *   setData: a function to update fetched data
 *   queryParams: current query params
 *   setQueryParams: a function to update queryParams. This would trigger re-fetching
 *                   if immediately is true
 *   fetchData: function to manually re-fetch the data
 *   isFetching: a flag that indicates whether the data is being fetched
 * }
 *  */
const useQuery = (queryKey, query, params = {}, immediately = true) => {
  const [data, setData] = useState();
  const [queryParams, setQueryParams] = useState(params);
  const [isFetching, setIsFetching] = useState(immediately);
  const fetchData = useCallback(
    async () => {
      setIsFetching(true);
      const data = await query(queryParams);
      setData(data);
      setIsFetching(false);
      return data;
    },
    [queryKey, query, queryParams] //eslint-disable-line
  );

  useEffect(() => {
    immediately && fetchData();
  }, [fetchData, immediately]);

  return { data, setData, queryParams, setQueryParams, fetchData, isFetching };
};

export default useQuery;
