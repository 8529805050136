import React, { useRef } from 'react';
import PropTypes from 'prop-types';
import { Field } from 'react-final-form';

import { InputWrapper } from './input_wrapper';
import {
  CheckboxWrapper,
  OptionLabel,
  StyledRadioButtonCard,
  StyledRadioInput,
} from '../styles/form';
import { getError } from './utils';
import Icon from '../icon';
import { Flex } from '../styles/flex';

export const RadioButton = ({ checked, label, name, onChange, value }) => {
  return (
    <CheckboxWrapper>
      <OptionLabel checked={checked}>
        <input
          type="radio"
          name={name}
          value={value}
          checked={checked}
          onChange={onChange}
        />
        <span>{label}</span>
      </OptionLabel>
    </CheckboxWrapper>
  );
};

RadioButton.propTypes = {
  checked: PropTypes.bool,
  label: PropTypes.string,
  name: PropTypes.string.isRequired,
  onChange: PropTypes.func,
  value: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.bool,
    PropTypes.number,
  ]),
};

export const RadioButtonCard = ({
  checked,
  icon,
  label,
  name,
  onChange,
  tooltip,
  value,
}) => {
  return (
    <StyledRadioButtonCard
      data-balloon-pos={'down'}
      data-balloon-length="fit"
      aria-label={tooltip}
      checked={checked}
      className="btn btn-default"
    >
      <Icon size={24} name={icon} />
      <div>{label}</div>
      <input
        type="radio"
        name={name}
        value={value}
        checked={checked}
        onChange={onChange}
        hidden={true}
      />
      <StyledRadioInput checked={checked} />
    </StyledRadioButtonCard>
  );
};

RadioButtonCard.propTypes = {
  ...RadioButton.propTypes,
  icon: PropTypes.string,
};

export const FormRadioButton = ({
  getOptionLabel,
  label,
  name,
  options,
  required,
  valueType,
  type,
  direction,
  width,
}) => {
  const errorRef = useRef('');
  // we want to have different default directions for card and simple types
  // that can be overriden by providing the direction prop
  const flexDirection = direction || (type === 'card' ? 'row' : 'column');
  const RadioButtonEl = type === 'card' ? RadioButtonCard : RadioButton;
  const gap = flexDirection === 'column' && type !== 'card' ? '' : '8px';
  return (
    <InputWrapper
      errorText={errorRef.current}
      name={name}
      labelProps={{ label, required }}
    >
      <Flex
        gap={gap}
        flexDirection={flexDirection}
        width={width}
        flexWrap="wrap"
      >
        {options.map(({ value, label, icon, tooltip }) => {
          return (
            <Field
              {...valueHandler[valueType]}
              key={value}
              type="radio"
              name={name}
              value={value}
            >
              {({ input, meta }) => {
                errorRef.current = getError(meta);
                const optionLabel = getOptionLabel
                  ? getOptionLabel({ value, label, icon })
                  : label;
                return (
                  <RadioButtonEl
                    {...input}
                    label={optionLabel}
                    icon={icon}
                    tooltip={tooltip}
                  />
                );
              }}
            </Field>
          );
        })}
      </Flex>
    </InputWrapper>
  );
};

const OptionType = {
  label: PropTypes.string.isRequired,
  value: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.bool,
    PropTypes.number,
  ]).isRequired,
  icon: PropTypes.string,
  tooltip: PropTypes.string,
};

FormRadioButton.propTypes = {
  direction: PropTypes.oneOf(['column', 'row']),
  getOptionLabel: PropTypes.func,
  label: PropTypes.string,
  name: PropTypes.string.isRequired,
  options: PropTypes.arrayOf(PropTypes.shape(OptionType)),
  required: PropTypes.bool,
  type: PropTypes.oneOf(['simple', 'card']),
  valueType: PropTypes.oneOf(['boolean', 'number', 'string']),
  width: PropTypes.string,
};

FormRadioButton.defaultProps = {
  valueType: 'string',
  type: 'simple',
};

const valueHandler = {
  boolean: {
    parse: JSON.parse,
  },
  number: {
    parse: Number,
  },
  string: {
    format: (value) => value || '',
  },
};
