import styled from 'styled-components';
import PropTypes from 'prop-types';
import { Flex } from '../styles/flex';
import { Checkbox, ErrorWrapper } from '../styles/form';
import { Field } from './field';
import { getError } from './utils';

const StyledLabel = styled.label`
  margin-bottom: 0;
  display: flex;
  gap: 16px;
  line-height: 18px;
  cursor: pointer;
`;

export const CheckboxField = ({
  customMessage,
  error: externalError,
  id,
  label,
  name,
  required,
}) => (
  <Field
    customMessage={customMessage}
    name={name}
    required={required}
    type="checkbox"
  >
    {({ meta, input }) => {
      const error = externalError || getError(meta);
      const className = error ? 'has-error' : '';
      return (
        <Flex
          data-testid={`input-wrapper_${name}`}
          flexDirection="column"
          className={className}
        >
          <StyledLabel alignItems="center" gap="16px">
            <Checkbox id={id} {...input} />
            <span htmlFor={name}>{label}</span>
          </StyledLabel>
          <ErrorWrapper>{error}</ErrorWrapper>
        </Flex>
      );
    }}
  </Field>
);

CheckboxField.propTypes = {
  customMessage: PropTypes.string,
  error: PropTypes.string,
  id: PropTypes.string,
  label: PropTypes.any,
  name: PropTypes.string.isRequired,
  required: PropTypes.bool,
};
