import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import { SearchBox, Label, Input } from './styles/search_term';

import useI18n from '../../lib/use_i18n';

const ClearBtn = styled.span`
  cursor: pointer;
`;

const SearchTerm = ({
  autoFocus,
  className,
  for: translationPrefix,
  onChange,
  searchTerm,
  id,
  placeholder,
}) => {
  const { translate } = useI18n(translationPrefix);

  const changed = (event) => onChange(event.target.value);

  return (
    <SearchBox className={className} role="search">
      <Label>
        <span className="material-symbols-outlined">search</span>
        <Input
          // We should only use it in popovers/modals,
          // otherwise, it could impede accessibility.
          autoFocus={autoFocus} //eslint-disable-line
          type="search"
          value={searchTerm}
          onChange={changed}
          placeholder={placeholder || translate('.search.placeholder')}
          id={id}
          role="searchbox"
        />
        {searchTerm !== '' && (
          /* eslint-disable-next-line */
          <ClearBtn
            onClick={(e) => {
              e.stopPropagation();
              onChange('');
            }}
            className="material-symbols-outlined"
          >
            clear
          </ClearBtn>
        )}
      </Label>
    </SearchBox>
  );
};

SearchTerm.propTypes = {
  autoFocus: PropTypes.bool,
  className: PropTypes.string,
  for: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  searchTerm: PropTypes.string,
  id: PropTypes.string,
  placeholder: PropTypes.string,
};

export default SearchTerm;
