/* eslint-disable react-hooks/exhaustive-deps */

import React, { useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import useI18n from '../../../lib/use_i18n';
import { InputWrapper } from './input_wrapper';
import { generateKeyDownHandler, getError } from './utils';
import { fontSize } from '../styles/variables';
import { Field } from './field';

const TagsWrapper = styled.div`
  background-color: #fff;
  display: inline-block;
  padding: 6px 0;
  color: #555;
  vertical-align: middle;
  line-height: 22px;
  cursor: text;
  border: 0;
  border-bottom: 1px solid #ccc;
  box-shadow: none;
  width: 100%;

  input[type='text'] {
    box-shadow: none;
    font-size: ${fontSize};
  }
  input::placeholder {
    font-size: ${fontSize};
  }
`;

const Tag = styled.span`
  display: inline-block;
  color: #666;
  font-size: 1em;
  background-color: #eeeeee;
  font-weight: normal;
  margin-right: 3px;

  span[data-role='remove'] {
    margin-left: 8px;
    cursor: pointer;
  }
  span[data-role='remove']:after {
    content: 'x';
    padding: 0px 2px;
  }
  span[data-role='remove']:hover {
    box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.2),
      0 1px 2px rgba(0, 0, 0, 0.05);
  }
  span[data-role='remove']:hover:active {
    box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  }
`;

const TagInput = styled.input`
  border: none;
  box-shadow: none;
  outline: none;
  background-color: transparent;
  padding: 0 6px;
  margin: 0;
  width: auto;
  max-width: inherit;

  &[type='text']:focus {
    border: none;
    box-shadow: none;
  }
`;

/**
 * Renders a tags field component.
 *
 *   <TagsField label="label"
 *     placeholder="placeholder"
 *     required="required"
 *     items={[value1, value2]}
 *     errorText="error"
 *     onChange={fn()}
 *   />
 *
 * A new tag is added when a user hits Enter, Tab or separate words with coma (every word will be
 * treated as a separate tag). Also, this component handle the paste event
 * see more there https://developer.mozilla.org/en-US/docs/Web/API/Element/paste_event
 */
export const TagsInput = ({
  errorText,
  id,
  items,
  label,
  onChange,
  hint,
  hintId,
  name,
  placeholder,
  required,
}) => {
  const { translate } = useI18n();

  const [tags, setTags] = useState(items);
  const inputRef = useRef();

  const addTags = (input) => {
    const uniqTags = new Set(tags);

    input.forEach((tag) => uniqTags.add(tag.trim()));

    setTags(Array.from(uniqTags));
  };

  const removeTag = (tag) => {
    const newTags = tag ? tags.filter((t) => t !== tag) : tags.slice(0, -1);

    setTags(newTags);
  };

  const keyDownHandler = generateKeyDownHandler({
    tags,
    type: 'tags',
    setTags,
  });

  const pasteHandler = (event) => {
    event.preventDefault();
    const paste = (event.clipboardData || window.clipboardData).getData('text');
    addTags(paste.split(','));
    event.currentTarget.value = '';
  };

  useEffect(() => {
    onChange(tags);
  }, [tags]);

  useEffect(() => {
    setTags(items);
  }, [items]);
  return (
    <InputWrapper
      errorText={errorText}
      hint={hint}
      hintId={hintId}
      name={name}
      labelProps={{
        className: 'hold-place',
        label,
        required,
      }}
    >
      <div className="form-group tags-list" aria-label={label}>
        <TagsWrapper
          onClick={() => {
            inputRef.current.focus();
          }}
        >
          {tags?.map((value) => (
            <Tag key={value} className="label label-info tag" role="insertion">
              {value}
              <span
                aria-label={translate('app.actions.delete')}
                onClick={() => removeTag(value)}
                aria-hidden="true"
              />
            </Tag>
          ))}
          &nbsp;
          <TagInput
            id={id}
            type="text"
            placeholder={tags.length > 0 ? '' : placeholder}
            size={tags.length > 0 ? '' : 30}
            onKeyDown={keyDownHandler}
            onPaste={pasteHandler}
            ref={inputRef}
          />
        </TagsWrapper>
      </div>
    </InputWrapper>
  );
};

TagsInput.defaultProps = {
  items: [],
};

export const TagsField = ({
  hint,
  hintId,
  id,
  label,
  name,
  placeholder,
  required,
}) => {
  return (
    <Field name={name} required={required}>
      {({ input, meta }) => (
        <TagsInput
          errorText={getError(meta)}
          hint={hint}
          hintId={hintId}
          id={id}
          items={input.value}
          label={label}
          name={input.name}
          onChange={input.onChange}
          placeholder={placeholder}
          required={required}
        />
      )}
    </Field>
  );
};

TagsField.propTypes = {
  hint: PropTypes.string,
  hintId: PropTypes.string,
  id: PropTypes.string,
  label: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  placeholder: PropTypes.string.isRequired,
  required: PropTypes.bool,
};

TagsInput.propTypes = {
  ...TagsField.propTypes,
  errorText: PropTypes.string,
  items: PropTypes.arrayOf(PropTypes.string),
  onChange: PropTypes.func.isRequired,
};
