import React, { useMemo } from 'react';

import { CountriesContext } from '../../../contexts';
import { FormSelect } from './select_field';

/**
 * Renders a dropdown with countries.
 *
 *     <CountrySelect name="country_code" label="Country" required={true} />
 */

const CountrySelectWrapper = ({ name, label, required, emptyOption }) => (
  <CountriesContext.Consumer>
    {(countries) => (
      <CountrySelect
        name={name}
        label={label}
        countries={countries}
        emptyOption={emptyOption}
        required={required}
      />
    )}
  </CountriesContext.Consumer>
);

const CountrySelect = ({ countries, required, emptyOption, label, name }) => {
  const options = useMemo(() => {
    const options = countries.map(([code, name]) => ({
      value: code,
      label: name,
    }));
    if (!required || emptyOption) {
      options.unshift({ value: '', label: '-' });
    }
    return options;
  }, [countries, required, emptyOption]);

  return (
    <FormSelect
      options={options}
      required={required}
      name={name}
      label={label}
    />
  );
};

export default CountrySelectWrapper;
