import styled from 'styled-components';
import { propsToAttributes, withCustomProps } from './utils';
import { lightBlue } from './variables';

export const BorderlessButton = styled.button`
  text-align: center;
  color: ${lightBlue};
  border: none;
  background-color: transparent;
  white-space: nowrap;
`;

export const Button = withCustomProps(styled.button`
  ${propsToAttributes}
`);
