export const FRAME_CONTRACT_STATE = {
  DRAFT: 'draft',
  REQUESTED: 'requested',
  LIVE: 'active',
  EXPIRED: 'expired',
  REJECTED: 'rejected',
};

export const SESSION_STORAGE_KEYS = {
  PREVIEW_DATA: 'previewData',
  LOCATION_LIST: 'locationListState',
  JOBS_LIST: 'jobsListState',
};

export const PUBLICATION_ENTITY = {
  JOB: 'job',
  REVISION: 'revision',
};

export const SUPPORT_EMAIL = 'service@gohiring.com';

export const HELPCENTER_URL = {
  FRAME_CONTRACTS:
    'https://helpcenter.gohiring.com/hc/de/categories/8484590392348-Rahmenverträge',
  JOB_GROUPS:
    'https://helpcenter.gohiring.com/hc/de/sections/8514330479644-Jobgruppen',
  LINKED_IN_JOBSLOTS:
    'https://helpcenter.gohiring.com/hc/de/sections/14863579393180-LinkedIn-Job-Slots',
  OPTIMIZE_ADS:
    'https://helpcenter.gohiring.com/hc/de/articles/23107392417437-Wie-kann-ich-meine-StepStone-Anzeigen-optimieren',
  ORDER_PAYMENT_ADDRESSES:
    'https://helpcenter.gohiring.com/hc/de/sections/8485030566300-Rechnungsdaten',
  PAYMENT_ADDRESSES:
    'https://helpcenter.gohiring.com/hc/de/categories/10865581441308-Rechnungsstellung',
  PAYMENT_ADDRESSES_RECIPIENT:
    'https://helpcenter.gohiring.com/hc/de/articles/15044553079708-Wie-prüfe-ich-ob-der-richtige-Rechnungsempfänger-hinterlegt-ist',
  REUSE_CONTENT:
    'https://helpcenter.gohiring.com/hc/de/articles/23439380669597-Wie-kann-ich-bereits-optimierte-Inhalte-wiederverwenden',
  STRUCTURED_ADS:
    'https://helpcenter.gohiring.com/hc/de/articles/23439200406173-Was-bewirkt-die-Funktion-Strukturierte-Anzeigen',
  TEMPLATES:
    'https://helpcenter.gohiring.com/hc/de/sections/12361844621596-Anzeigendesign',
  USER_FILTER:
    'https://helpcenter.gohiring.com/hc/de/articles/23439629240477-Warum-zeigt-der-Filter-keine-Jobs-für-meinen-Benutzer-an',
};

export const JOBSTREAM_URLS = {
  FRAME_CONTRACTS: '/frame_contracts',
};

export const NEWSLETTER_URL = 'https://gohiring.com/newsletter/';

export const AD_TYPE_ICON = {
  text: 'article',
  html: 'code_blocks',
  text_with_image: 'newsmode',
  template: 'newsmode',
};

export const MEDIA_ASSET_TYPE = {
  header: 'header',
  background: 'background',
};

export const POSTING_STATE = {
  NEW: 'new',
  PENDING: 'pending',
  EXPIRED: 'expired',
  POSTED: 'posted',
  AWAITING_EXPIRATION: 'awaiting_expiration',
  AWAITING_POSTING: 'awaiting_posting',
  SUBMITTED: 'submitted',
  CANCELLED: 'cancelled',
};

export const JOB_EXPIRED_STATE = 'expired';

export const ACTION_TYPE = {
  archive: 'archive',
  delete: 'delete',
  create: 'create',
  update: 'update',
  unarchive: 'unarchive',
};
