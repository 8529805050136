import { useEffect, useState } from 'react';
import { validateImage } from '../../../../store';

// A helper hook that takes away the complexity from the ImageUploader
// component. It calculates and updates provided props and returns only the
// necessary values for the ImageUploader to consume.
export const useGenerateImageUploaderValues = ({
  handleError,
  initialImageUrl,
  instantValidation,
  onChange,
  validationError,
}) => {
  const shouldValidate =
    instantValidation && initialImageUrl && !validationError;
  const [remoteValidationError, setRemoteValidationError] =
    useState(validationError);
  const [validatingRemoteImage, setValidatingRemoteImage] =
    useState(shouldValidate);
  const [imageUrl, setImageUrl] = useState(initialImageUrl);

  const handleRemoteValidateResult = (error = null) => {
    setRemoteValidationError(error);
    setValidatingRemoteImage(false);
  };

  // If component receives pre-assigned imageUrl it has to call backend to validate it
  // The url could be broken or pointing to an invalid image.
  useEffect(() => {
    (async () => {
      try {
        if (shouldValidate) {
          await validateImage(initialImageUrl);
          handleRemoteValidateResult();
        }
      } catch (error) {
        handleRemoteValidateResult(error?.response?.data?.error);
      }
    })();
  }, []); //eslint-disable-line

  useEffect(() => {
    setImageUrl(initialImageUrl);
  }, [initialImageUrl]);

  useEffect(() => {
    onChange?.(imageUrl);
  }, [imageUrl]); //eslint-disable-line

  useEffect(() => {
    handleError &&
      handleError({
        error: remoteValidationError,
        isValidating: validatingRemoteImage,
      });
  }, [remoteValidationError, validatingRemoteImage]); //eslint-disable-line

  const handleSelect = ({ url }) => {
    setImageUrl(url);
    setRemoteValidationError();
  };

  const handleRemoveConfirmation = async (hideConfirmation) => {
    setImageUrl('');
    setRemoteValidationError();
    setValidatingRemoteImage(false);
    hideConfirmation();
  };

  const showImage =
    !validatingRemoteImage && !remoteValidationError && imageUrl;

  return {
    handleRemoveConfirmation,
    handleSelect,
    imageUrl,
    showImage,
  };
};
