import React, { useState } from 'react';
import { useDropzone } from 'react-dropzone';
import useI18n from '../../../../../lib/use_i18n';
import { DropZone } from './drop_zone';
import { ImageUpload } from './image_upload';
import { fileValidator } from '../utils';

export const UploadImage = ({
  close,
  formatHint,
  handleSelect,
  handleUpload,
  maxFileSize,
  maxWidth,
  minWidth,
  successMsg,
  uploadErrorMsg,
  validFileFormats,
}) => {
  const { translate } = useI18n('image_uploader');
  const [fileError, setFileError] = useState(null);
  const [file, setFile] = useState();
  const { getRootProps, getInputProps, open } = useDropzone({
    noClick: true,
    noKeyboard: true,
    onDrop: async (files) => {
      setFileError(null);
      const [fileAdded] = files.map((file) =>
        Object.assign(file, {
          preview: URL.createObjectURL(file),
        })
      );
      setFile(fileAdded);
      setFileError(
        await fileValidator({
          file: fileAdded,
          maxFileSize,
          maxWidth,
          minWidth,
          translate,
          validFileFormats,
        })
      );
    },
  });

  return !file ? (
    <DropZone
      validFileFormats={validFileFormats}
      formatHint={formatHint}
      getInputProps={getInputProps}
      getRootProps={getRootProps}
      maxFileSize={maxFileSize}
      open={open}
    />
  ) : (
    <ImageUpload
      file={file}
      handleUpload={handleUpload}
      setFile={setFile}
      handleSelect={handleSelect}
      uploadErrorMsg={uploadErrorMsg}
      fileError={fileError}
      setFileError={setFileError}
      close={close}
      successMsg={successMsg}
    />
  );
};
