import { useRef } from 'react';

import useI18n from '../../../../../lib/use_i18n';
import { Flex } from '../../../styles/flex';
import { Span } from '../../../styles/text';
import { fontSizeXS, gray } from '../../../styles/variables';
import { ImageRow } from './row';

export const List = ({
  close,
  deleteImage,
  generateHandleUpdate,
  handleSelect,
  items,
  selectedImage,
  setSelectedImage,
  totalCount,
}) => {
  const { translate } = useI18n('image_uploader.select_image');
  const listRef = useRef();

  return (
    <Flex
      ref={listRef}
      data-testid="image-list"
      overflow="auto"
      width="50%"
      flexDirection="column"
      max-height="350px"
    >
      <Span marginBottom="8px" fontSize={fontSizeXS} color={gray}>
        {translate('.images', {
          count: items.length,
          totalCount,
        })}
      </Span>
      {items.map((image) => (
        <ImageRow
          listRef={listRef}
          key={image.url}
          active={selectedImage?.url === image.url}
          image={image}
          onClick={setSelectedImage}
          generateHandleUpdate={generateHandleUpdate}
          handleDelete={deleteImage}
          onDoubleClick={(image) => {
            handleSelect(image);
            close();
          }}
        />
      ))}
    </Flex>
  );
};
