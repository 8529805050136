/**
 * A small wrapper around the GA interface, see more there https://bit.ly/2MNasx3
 */
window.dataLayer = window.dataLayer || [];

const itemValue = ({ salesPrice, frameContract }) =>
  parseFloat(salesPrice) +
  (parseFloat((frameContract || {}).postingPrice) || 0);

const itemAffiliation = ({ frameContract }) =>
  frameContract ? 'with frame_contract' : 'without frame_contract';

/**
 * Use this if you need to add information about a channel to
 * an analytics event.
 */
export const analyzeItem = (item) => ({
  item_id: item.id,
  item_name: item.name,
  item_currency: item.currencyCode,
  item_value: itemValue(item),
  item_affiliation: itemAffiliation(item),
  item_rating: item.rating,
});

export const pushAnalytics = (item) => window.dataLayer.push(item);
