import React from 'react';
import PropTypes from 'prop-types';
import Switch from 'react-switch';
import styled from 'styled-components';

/**
 * Our wrapper for https://www.npmjs.com/package/react-switch
 */

const SWITCH_HEIGHT = 20;
const Wrapper = styled.span`
  display: inline-box;
  max-height: ${SWITCH_HEIGHT}px;
  //making input el the size of the switch component so we can track clicks
  input {
    bottom: 0;
    cursor: pointer;
    left: 0;
    margin: 0;
    opacity: 0;
    position: absolute;
    right: 0;
    top: 0;
  }
`;

const INPUT_STYLE = {
  height: 'auto',
  width: 'auto',
};

const ToggleField = ({ id, input, trackingId }) => (
  <Wrapper>
    <Switch
      activeBoxShadow="0px 0px 1px 10px rgba(0, 0, 0, 0.2)"
      boxShadow="0px 1px 5px rgba(0, 0, 0, 0.6)"
      checked={input.value}
      checkedIcon={false}
      className="react-switch"
      data-trackingid={trackingId}
      handleDiameter={SWITCH_HEIGHT}
      height={SWITCH_HEIGHT}
      id={id}
      name={input.name}
      onChange={input.onChange}
      onColor="#86d3ff"
      onHandleColor="#2693e6"
      style={INPUT_STYLE}
      uncheckedIcon={false}
      width={48}
    />
  </Wrapper>
);

ToggleField.propTypes = {
  id: PropTypes.string,
  input: PropTypes.shape({
    value: PropTypes.bool,
    onChange: PropTypes.func.isRequired,
  }),
  trackingId: PropTypes.string,
};

export default ToggleField;
