export const fileValidator = async ({
  file,
  maxFileSize,
  maxWidth,
  minWidth,
  translate,
  validFileFormats,
}) => {
  return (
    validateFileSize({ file, maxFileSize, translate }) ||
    validateFileFormats({ file, translate, validFileFormats }) ||
    (await validateImageWidth({ file, minWidth, maxWidth, translate }))
  );
};

const validateFileSize = ({ file, maxFileSize, translate }) => {
  if (file && file.size && file.size > maxFileSize) {
    return translate('image_uploader.errors.size', {
      size: humanFileSize(maxFileSize, 'mb'),
    });
  }
};

const validateFileFormats = ({ file, validFileFormats, translate }) => {
  if (
    file &&
    validFileFormats.every(
      (fileExtension) => FILE_TYPES[fileExtension] !== file.type
    )
  ) {
    return translate('image_uploader.errors.format', {
      formats: formatsToText({ formats: validFileFormats, translate }),
    });
  }
};

const validateImageWidth = async ({ file, minWidth, maxWidth, translate }) => {
  if (minWidth || maxWidth) {
    return new Promise((resolve) => {
      const image = new Image();

      image.addEventListener('load', () => {
        if (minWidth && image.width < minWidth) {
          resolve(
            translate('image_uploader.errors.min_width', {
              minWidth,
            })
          );
        }
        if (maxWidth && image.width > maxWidth) {
          resolve(
            translate('image_uploader.errors.max_width', {
              maxWidth,
            })
          );
        }

        resolve();
      });

      image.src = URL.createObjectURL(file);
    });
  }
};

export const formatsToText = ({ translate, formats }) =>
  formats.reduce((acc, format, index) => {
    const separator = acc
      ? index === formats.length - 1
        ? ` ${translate('app.or')} `
        : ', '
      : '';
    return `${acc}${separator}${format}`;
  });

const FILE_TYPES = {
  png: 'image/png',
  jpeg: 'image/jpeg',
  gif: 'image/gif',
};

// Aux: Convert file size from bytes to mb or kb to show in message and errors
export const humanFileSize = (bytes, unit) => {
  const factor = unit === 'mb' ? 1024 * 1024 : 1024;

  return Math.round(((bytes || 0) / factor) * 100) / 100;
};

// calculates greatest common denominator
const gcd = (a, b) => (b === 0 ? a : gcd(b, a % b));
export const aspectRatio = ({ width, height }) => {
  if (!width || !height) {
    return null;
  }
  const divisor = gcd(width, height);
  return `${width / divisor}:${height / divisor}`;
};

export const dimensionsToString = ({ width, height }) => {
  return `${width} x ${height} px`;
};

export const imageFitRecommendedDimensions = ({
  recommendedDimensions,
  imageDimensions,
}) =>
  recommendedDimensions.width === imageDimensions.width &&
  recommendedDimensions.height === imageDimensions.height;
