import styled from 'styled-components';
import useI18n from '../../../../../lib/use_i18n';
import Notification from '../../../notification';
import { Span } from '../../../styles/text';
import { borderLightGray, fontSizeXS, gray } from '../../../styles/variables';
import {
  aspectRatio,
  dimensionsToString,
  imageFitRecommendedDimensions,
} from '../utils';
import { useImageDimensions } from './use_image_dimensions';

const IMAGE_CROPPER_LINK = `https://imagecropper.stepstone.com/?lang=`;
export const Preview = ({ selectedImage, recommendedDimensions }) => {
  const { translate, formattedDate, generateLink, locale } = useI18n(
    'image_uploader.select_image'
  );
  const imageDimensions = useImageDimensions(selectedImage?.url);
  return (
    <PreviewWrapper>
      <Span fontSize={fontSizeXS} color={gray}>
        {translate('app.actions.preview')}
      </Span>
      <ImgWrapper>
        <img
          data-testid="preview-image"
          src={selectedImage?.url}
          alt={selectedImage?.name}
        />
      </ImgWrapper>
      {selectedImage && (
        <Details>
          <span>{selectedImage?.name}</span>
          <span>
            <span>{translate('.uploaded_on')}</span>{' '}
            <span>{formattedDate(selectedImage?.created_at)}</span>
          </span>
          <RatioDimensions>
            <span>
              <span>{translate('.ratio')}</span>:{' '}
              <span>{aspectRatio(imageDimensions)}</span>
            </span>
            <span>
              <span>{translate('.dimensions')}</span>:{' '}
              <span>{dimensionsToString(imageDimensions)}</span>
            </span>
          </RatioDimensions>
          {!imageFitRecommendedDimensions({
            imageDimensions,
            recommendedDimensions,
          }) && (
            <Notification
              kind="warning"
              title={translate('.notification.title')}
              text={translate('.notification.text', {
                ratio: aspectRatio(recommendedDimensions),
                dimensions: dimensionsToString(recommendedDimensions),
                imageCropperLink: generateLink({
                  text: translate('.notification.image_cropper'),
                  href: `${IMAGE_CROPPER_LINK}${locale}`,
                }),
              })}
            />
          )}
        </Details>
      )}
    </PreviewWrapper>
  );
};

const ImgWrapper = styled.div`
  overflow: hidden;
  max-width: 100%;
  max-height: 100%;
  flex-shrink: 3;
  & > img {
    width: auto;
    max-height: 100%;
    max-width: fit-content;
    border: 1px solid ${borderLightGray};
    border-radius: 3px;
  }
`;

const RatioDimensions = styled.div`
  display: flex;
  gap: 16px;
  &:not(:last-child) {
    margin-bottom: 12px;
  }
`;

const Details = styled.div`
  display: flex;
  flex-direction: column;
  color: ${gray};
  font-size: ${fontSizeXS};
`;

const PreviewWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
`;
