/**
 * Provides the useFilters hook, which can be used to filter and sort a list of items.
 *
 * Usage:
 *
 *   const { filters, items } = useFilters(records, definitions, initialState, searchKeys);
 *
 * Parameters:
 *   records - A list of objects. Should be constant, re-rendering with a different list of records
 *             will have no effect.
 *   definitions - An object containing filters implementing a match function. See below how to
 *                 implement them.
 *   initialState - An object containing the initial state for each filter.
 *   searchKeys - A list of fields considered in the search feature.
 *
 * Returns:
 *   items - The filtered and sorted list of items, ready to be displayed. Note that useFilters
 *           does not handle pagination.
 *   filters - An object of objects, enumerating the current state of each filter and providing
 *             controls for modifying this state.
 *
 *
 * CREATING FILTERS:
 *
 * Each filter should be an object that must contain, at a minimum:
 *   match - A function taking two parameters: an item, and the filter's current state. It should return
 *           a boolean value denoting whether or not the item is matched by the filter. It may be assumed
 *           that the function will not be called if the filter is inactive (i.e. its state is falsy).
 *   count - A function that takes the complete list of items, and counts how many would be matched by the
 *           filter when active. The return value may be differ depending on the type of filter, e.g. toggle
 *           filters may return a single number, while multiple-choice filters may return an object containing
 *           the match count for each option.
 *
 * For multiple-choice filters, the following fields may also be specified:
 *   options - A list of options, from which the user may select one or more values.
 *   countSelected - When this is set to true, and the filter is active, useFilters will count the number of
 *                   items that match the filter's current state and include it as a part of the returned filters
 *                   object.
 *
 *
 * THE RETURNED `filters` OBJECT:
 *
 * For each filter specified in FILTERS, the `filters` object returned by useFilters will contain an entry of the
 * same name, each containing the following fields:
 *   name - The filter's name. Redundant but useful.
 *   state - The current state of the filter. Different kinds of filter may store different kinds of value in this
 *           field, but when the value is falsy (false, null, undefined, 0, ''), the filter will be considered to
 *           be inactive.
 *   setState - A function taking a single parameter. Sets the `state` value for this filter, recomputes the
 *              filtered list of items and triggers rerendering of the containing component.
 *   options - The list of options, exactly as specified in the filter's definition.
 *   count - The value returned by the filter's count function.
 *   selectedCount - When countSelected is true and the filter is active, gives a number of items currently matched
 *                   by the filter.
 *
 * In addition to the filters specified in FILTERS, the filters object will also contain:
 *   search - A text-search filter. An object containing:
 *            state - A string that is the current search term.
 *            setState - A function for updating the current search term.
 *   order - Specifies the sort order for the filtered list. Contains:
 *           by - The field items should be sorted by.
 *           ascending - A boolean value specifying ascending or descending sort order.
 *           criteria - A list of {by, ascending} objects, it provides secondary sorting criteria.
 *                      It overrides `by` and `ascending` values when provided
 *           setState - A function accepting two parameters, `by` and `ascending`.
 *           customSort - An onbject, with fields as keys, containing custom sorting functions.
 *                        It can be provided on filter creation, as the part of the initialState argument.
 *                        Accepts `by` (field name) and `ascending`, returns sorting function: (a,b) => number
 *   active - A boolean value that will be true if any filters (including search) are currently active.
 *   reset - A function that resets all filters to be inactive. Does not affect sort order.
 *   refresh - A function that causes all filters and counts to be recalculated. Should be called if any items
 *             are modified (e.g. A channel gets added to favourites).
 */
import { useMemo, useState, useRef } from 'react';
import PropTypes from 'prop-types';
import Fuse from 'fuse.js';
import { pushAnalytics } from './analytics_tracker';
import { basicSort, sortByMultipleCriteria } from './list';
import { useCallback } from 'react';

const FUSE_OPTIONS = {
  shouldSort: true,
  findAllMatches: true,
  threshold: 0.1,
  location: 0,
  distance: 500,
  maxPatternLength: 32,
  minMatchCharLength: 2,
  ignoreLocation: true,
};

// eslint panics about the usage of hooks in this library, because they get called
// conditionally and from within callbacks. But everything is constant and deterministic,
// hooks always get called the same number of times and in the same order. So it's fine.
/* eslint-disable react-hooks/rules-of-hooks */

const useFilters = (
  records,
  definitions,
  initialState,
  searchKeys,
  fuseOptions
) => {
  const [state, setState] = useState({
    term: '',
    order: null,
    ...initialState,
  });
  // Compute the search index only once for efficiency. Be careful, sorting or modifying the list of records
  // will cause text search to break.
  let searchIndex,
    fuseOpts = { ...FUSE_OPTIONS, ...fuseOptions };

  if (searchKeys) {
    searchIndex = useMemo(
      () => Fuse.createIndex(searchKeys, records),
      [records]
    );
    fuseOpts = Object.assign({ keys: searchKeys }, fuseOpts);
  }

  const fuse = new Fuse(records, fuseOpts, searchIndex);

  const useFilter = (name, filter) => ({
    name,
    options: filter.options,
    state: state[name],
    setState: (newState) => setState({ ...state, [name]: newState }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    count: filter.count && useMemo(() => filter.count(records), [state.force]),
    selectedCount:
      filter.countSelected &&
      state[name] &&
      records.filter((record) => filter.match(record, state[name])).length,
  });

  const filterNames = Object.keys(definitions);

  const filters = {};

  filterNames.forEach(
    (name) => (filters[name] = useFilter(name, definitions[name]))
  );

  let items =
    state.term === ''
      ? [...records]
      : fuse.search(state.term).map((r) => r.item);

  items = filterNames.reduce(
    (filtered, name) =>
      state[name]
        ? filtered.filter((record) =>
            definitions[name].match(record, state[name])
          )
        : filtered,
    items
  );

  if (state.order) {
    items.sort(getSortCallback(state.order));
  }

  const reset = useCallback(
    () => setState((state) => ({ term: '', order: state.order })),
    []
  );
  const refresh = useCallback(
    () => setState((state) => ({ ...state, force: new Date().getTime() })),
    []
  );

  const itemCount = useRef(0);
  itemCount.current = items.length;
  const trackSearchTimeoutId = useRef(null);
  const setSearchTerm = (term) => {
    if (trackSearchTimeoutId.current)
      clearTimeout(trackSearchTimeoutId.current);

    trackSearchTimeoutId.current = setTimeout(
      () =>
        pushAnalytics({
          event: 'search',
          searchTerm: term,
          searchResults: itemCount.current,
        }),
      500
    );

    setState({ ...state, term });
  };

  filters.search = {
    state: state.term,
    setState: setSearchTerm,
  };

  const order = {
    ...state.order,
    setState: (by, ascending) =>
      setState({ ...state, order: { ...state.order, by, ascending } }),
  };

  const active =
    state.term !== '' || Object.values(filters).some((f) => f.state);

  return { items, filters: { ...filters, active, order, reset, refresh } };
};

const FILTER_PROPS = {
  // The name of the filter
  name: PropTypes.string.isRequired,

  // The current state of the filter. Value depends on the type of filter,
  // when !state the filter is inactive
  state: PropTypes.any,

  // A function that may be used to update the state of the filter, causing the filtered list of items
  // to be recomputed and the containing component to be rerendered
  setState: PropTypes.func.isRequired,

  // A count of the items that will be matched by the filter when it is active
  count: PropTypes.oneOfType([
    PropTypes.number,
    PropTypes.objectOf(PropTypes.number),
  ]),
};

// Fields that will be present in all filters
export const FilterShape = PropTypes.shape(FILTER_PROPS);

// Fields that will be present in multiple-choice filters
export const MultiFilterShape = PropTypes.shape({
  ...FILTER_PROPS,

  // A list of options available in the filter
  options: PropTypes.array.isRequired,

  // The number of items currently matching the filter in its current state
  selectedCount: PropTypes.number,
});

export default useFilters;

const getSortCallback = ({ by, ascending, criteria, customSort }) => {
  const sortWrapper =
    customSort?.[by] || (criteria ? sortByMultipleCriteria : basicSort);
  const sortArgs = criteria || [by, ascending];
  return sortWrapper(...sortArgs);
};
