import PropTypes from 'prop-types';
import React, { useEffect, useRef, useState } from 'react';
import { useForm } from 'react-final-form';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import useI18n from '../../../../lib/use_i18n';
import {
  PlaceholderQuill,
  StyledInputWrapper,
  StyledQuill,
} from '../../styles/editor';

import { HiddenInput } from '../../styles/form';
import { Field } from '../field';
import { getError, normalizeInitialValue } from '../utils';
import {
  generateIcons,
  generateModules,
  generateTranslations,
  setup,
} from './utils';

/**
 * Editor component renders wysiwyg editor. its value can be
 * rendered as html. The list of editing commands can be found
 * in the ./utils.js TOOLBAR const.
 *
 *   className: Additional CSS class name for the component.
 *   disabled: Determines whether the editor is disabled or not.
 *   errorText: The error message to display, if any.
 *   hint: A popup text for the help icon in the top right.
 *   hintId: An id for the help icon button wrapper.
 *   initialValue: The initial value of the editor.
 *   inputAttrs: Additional attributes to be applied to the hidden input element.
 *   label: The label text for the editor.
 *   name: The name of the input field.
 *   namespace: For generating tracking id for potential error messages.
 *   placeholder: The placeholder text for the editor.
 *   required: affect how the label will look.
 */

setup(ReactQuill.Quill);
const modules = generateModules(ReactQuill.Quill);

export const Editor = ({
  className,
  disabled,
  errorText,
  form,
  hint,
  hintId,
  inputAttrs,
  label,
  labelProps,
  name,
  namespace,
  onBlur,
  onChange,
  onFocus,
  placeholder,
  required,
  value,
}) => {
  const [localValue, setLocalValue] = useState(value);
  const [isActive, setIsActive] = useState(false);
  const [isExpanded, setIsExpanded] = useState(false);
  const [showPlaceholder, setShowPlaceholder] = useState(
    Boolean(!value && placeholder)
  );
  const handleOnChange = (value, delta, source) => {
    const quill = quillRef?.current?.getEditor();

    // prevent empty paragraph to appear as non-empty value
    const normalizedValue = quill?.getLength() > 1 ? value : '';

    setLocalValue(normalizedValue);

    // when we receive external data the editor will format it,
    // making field effectively dirty, so we have to reset the form with the
    // formatted value. This is done only if no manual changes have been made:
    // e.g. loading initial values, reseting the form..
    if (normalizedValue && form && source === 'api') {
      normalizeInitialValue({ form, value: normalizedValue, name });
    } else {
      onChange && onChange(normalizedValue);
    }
  };

  const quillRef = useRef(null);
  const initialized = useRef(false);
  const { translate } = useI18n('app.form.editor');

  if (!initialized.current) {
    generateIcons({ Quill: ReactQuill.Quill, translate });
    initialized.current = true;
  }
  // actions on the first render
  useEffect(() => {
    if (value) {
      const quill = quillRef.current.getEditor();
      const delta = quill.clipboard.convert(value);
      quill.setContents(delta);
    }
  }, []); //eslint-disable-line

  useEffect(() => {
    // if showPlaceholder changes value to false, we want to focus
    // on quill editor if active
    if (!showPlaceholder && isActive) {
      const quill = quillRef.current.getEditor();
      quill.focus();
    }
  }, [showPlaceholder, isActive]); //eslint-disable-line

  useEffect(() => {
    // hide placeholder on focus
    isActive && setShowPlaceholder(false);
  }, [isActive]);

  useEffect(() => {
    // hide placeholder on programatic value change
    // e.g. fill values from profile/draft
    setShowPlaceholder(!isActive && !value && placeholder);
  }, [value, placeholder, isActive]);

  return (
    <StyledInputWrapper
      className={className}
      errorText={errorText}
      name={name}
      namespace={namespace}
      hint={hint}
      hintId={hintId}
      labelProps={{ label, required, ...labelProps }}
    >
      <div
        onFocus={() => {
          setIsActive(true);
        }}
      >
        {/* Quill does not support html as a placeholder, so we had to
          implement two versions of the editor: one will be utilized
          as a placeholder that shows html, the other one is 'real' editor */}
        <PlaceholderQuill
          disabled={disabled}
          hide={!showPlaceholder || !placeholder}
          id={`${name}_placeholder`}
          modules={{ toolbar: { container: [] } }}
          value={placeholder}
        />
      </div>

      <StyledQuill
        className="quillEditor"
        disabled={disabled}
        hide={showPlaceholder}
        id={`${name}_editor`}
        isActive={isActive}
        isExpanded={isExpanded}
        modules={modules}
        onBlur={() => {
          setIsActive(false);
          if (!quillRef.current.editingArea.contains(document.activeElement)) {
            setIsExpanded(false);
          }
          onBlur && onBlur();
        }}
        onChange={handleOnChange}
        onFocus={() => {
          setIsActive(true);
          setIsExpanded(true);
          onFocus && onFocus();
        }}
        ref={quillRef}
        theme={'snow'}
        translations={generateTranslations({ translate })}
        value={onChange ? value : localValue}
      />
      <HiddenInput
        {...inputAttrs}
        readOnly
        disabled={disabled}
        id={name}
        name={name}
        onFocus={() => {
          // we take focus from FocusOnError, and handle it so that
          // the quill editor becomes active
          setIsActive(true);
        }}
        value={onChange ? value : localValue}
      />
    </StyledInputWrapper>
  );
};

Editor.defaultProps = {
  errorText: null,
  additionalHiddenFields: null,
};

Editor.propTypes = {
  additionalHiddenFields: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      name: PropTypes.string.isRequired,
      value: PropTypes.string,
    })
  ),
  className: PropTypes.string,
  disabled: PropTypes.bool,
  errorText: PropTypes.string,
  hint: PropTypes.string,
  hintId: PropTypes.string,
  inputAttrs: PropTypes.object,
  label: PropTypes.string,
  name: PropTypes.string.isRequired,
  namespace: PropTypes.string,
  onBlur: PropTypes.func,
  onChange: PropTypes.func,
  onFocus: PropTypes.func,
  placeholder: PropTypes.string,
  required: PropTypes.bool,
  value: PropTypes.string,
};

export const EditorField = ({
  additionalHiddenFields,
  className,
  disabled,
  hint,
  hintId,
  inputAttrs,
  label,
  labelProps,
  name,
  namespace,
  placeholder,
  required,
  ...fieldProps
}) => {
  const form = useForm();
  return (
    <Field {...fieldProps} name={name} required={required}>
      {({ input, meta }) => (
        <Editor
          {...input}
          additionalHiddenFields={additionalHiddenFields}
          className={className}
          disabled={disabled}
          errorText={getError(meta)}
          form={form}
          hint={hint}
          hintId={hintId}
          inputAttrs={inputAttrs}
          labelProps={{ label, required, ...labelProps }}
          namespace={namespace}
          placeholder={placeholder}
          required={required}
        />
      )}
    </Field>
  );
};

EditorField.propTypes = {
  ...Field.propTypes,
  ...Editor.propTypes,
};
