import React, { useState } from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';

import Icon from './icon';
import useI18n from '../../lib/use_i18n';

const Wrapper = styled.div`
  display: inline-flex;
  min-width: 140px;
  gap: 4px;
  height: 32px;
  .material-symbols-outlined {
    line-height: 1.5;
  }
`;

/**
 * Renders a confirm and cancel buttons. Once confirmed, both buttons get disabled.
 */
export const Btns = ({
  action,
  btnClass,
  cancelClass,
  className,
  confirmClass,
  hide,
  onConfirmation,
}) => {
  btnClass = btnClass || 'btn-sm';

  const { translate } = useI18n('app.actions');

  const [disabled, setDisabled] = useState(false);

  const handleConfirmation = async (e) => {
    e.stopPropagation();
    setDisabled(true);
    await onConfirmation(hide, action);
  };

  return (
    <Wrapper className={className}>
      <button
        onClick={handleConfirmation}
        className={`btn ${btnClass} ${confirmClass}`}
        disabled={disabled}
      >
        {translate('.confirm_decision')}
      </button>
      <button
        onClick={hide}
        title={translate('.cancel_deletion')}
        className={`btn btn-default ${btnClass} ${cancelClass}`}
        disabled={disabled}
      >
        <Icon name="close" />
      </button>
    </Wrapper>
  );
};

Btns.propTypes = {
  action: PropTypes.any,
  btnClass: PropTypes.string,
  cancelClass: PropTypes.string,
  className: PropTypes.string,
  confirmClass: PropTypes.string,
  hide: PropTypes.func,
  onConfirmation: PropTypes.func.isRequired,
};

/**
 * Shows a confirmation and cancel buttons after clicking on a button (or link) which is provided
 * via a children prop.
 *
 *    <InlineConfirmation onConfirmation={ handleConfirmation }>
 *      { (clickHandler) => <a onClick={ clickHandler }>Delete</a> }
 *    </InlineConfirmation
 *
 * Optionally, the click handler passed to the children prop accepts an action which will be passed
 * as the second argument to the given onConfirmation callback. The first argument is a function
 * which can be called to hide the control buttons of this component.
 *
 *    const handleConfirmation = (hide, action) => { };
 */
const InlineConfirmation = ({
  onConfirmation,
  btnClass,
  confirmClass,
  cancelClass,
  className,
  children,
}) => {
  const [action, setAction] = useState(null),
    hide = (e) => {
      e?.stopPropagation();
      setAction(null);
    };

  const handleShowConfirmation = (action = 'none') => {
    action?.stopPropagation?.();
    action?.preventDefault?.();
    setAction(action);
  };

  if (action)
    return (
      <Btns
        action={action}
        hide={hide}
        btnClass={btnClass}
        cancelClass={cancelClass}
        className={className}
        confirmClass={confirmClass}
        onConfirmation={onConfirmation}
      />
    );

  return children(handleShowConfirmation);
};

InlineConfirmation.propTypes = {
  onConfirmation: PropTypes.func.isRequired,
  btnClass: PropTypes.string,
  confirmClass: PropTypes.string,
  cancelClass: PropTypes.string,
  children: PropTypes.func,
  className: PropTypes.string,
};

export default InlineConfirmation;
