import PropTypes from 'prop-types';
import styled from 'styled-components';
import {
  borderLightGray,
  red,
  lightBlue,
  yellow,
  green,
  gray,
} from './styles/variables';

const ProgressWrapper = styled.div`
  width: 100%;
  position: relative;
  height: 6px;
  background: ${borderLightGray};
  overflow: hidden;
  border-radius: 3px;
`;

const Bar = styled.div`
  height: 6px;
  position: absolute;
  background: ${({ color }) => color};
`;

export const PROGRESS_BAR_TYPES = {
  danger: 'danger',
  disabled: 'disabled',
  info: 'info',
  success: 'success',
  warning: 'warning',
};

const colors = {
  [PROGRESS_BAR_TYPES.danger]: red,
  [PROGRESS_BAR_TYPES.disabled]: gray,
  [PROGRESS_BAR_TYPES.info]: lightBlue,
  [PROGRESS_BAR_TYPES.success]: green,
  [PROGRESS_BAR_TYPES.warning]: yellow,
};

export const ProgressBar = ({ percentage, type }) => {
  return (
    <ProgressWrapper>
      <Bar
        role="progressbar"
        color={colors[type]}
        style={{ width: `${percentage}%` }}
      />
    </ProgressWrapper>
  );
};

ProgressBar.defaultProps = {
  type: PROGRESS_BAR_TYPES.info,
};

ProgressBar.propTypes = {
  color: PropTypes.string,
  percentage: PropTypes.number,
  type: PropTypes.oneOf(Object.values(PROGRESS_BAR_TYPES)),
};
