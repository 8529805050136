import styled from 'styled-components';
import { propsToAttributes, withCustomProps } from './utils';
import { deepBlue, fontSizeXS, lightBlue, lightGray } from './variables';

// hides text if it occupies more than N lines (computation is based in the given max height)
export const TextOverflow = withCustomProps(styled.div`
  max-height: ${(props) => props.maxHeight || 50}px;

  display: -webkit-box;
  -webkit-line-clamp: ${(props) =>
    Math.floor((props.maxHeight || 50) / 50) + 1};
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
`);

export const TextSeparator = styled.span`
  padding: 0 8px;

  :after {
    content: '|';
  }
`;

export const Span = withCustomProps(styled.span`
  ${propsToAttributes}
`);

export const Badge = withCustomProps(styled.span`
  font-size: ${fontSizeXS};
  padding: 0.2em 0.6em;
  border-radius: 0.25em;
  background: ${lightGray};
  color: white;
  line-height: 1.2;
  text-transform: uppercase;
  ${propsToAttributes}
`);

export const Link = styled.a.attrs({
  target: '_blank',
  rel: 'noreferrer',
})`
  text-decoration: none;
  color: ${lightBlue};
  font-size: inherit;

  &:hover {
    text-decoration: none;
    color: ${deepBlue};
    cursor: pointer;
  }
`;
