import { useEffect, useState } from 'react';

import useFilters from '../../../../../lib/use_filters';
import useQuery from '../../../../../lib/use_query';

export const useGenerateSelectImageValues = ({
  fetchImages,
  handleDelete,
  selectedImageUrl,
  handleRename,
}) => {
  const [selectedImage, setSelectedImage] = useState(null);
  const { data: images = [], setData: setImages } = useQuery(
    'mediaAssets',
    fetchImages
  );
  const {
    items,
    filters: { search },
  } = useFilters(
    images,
    {},
    { order: { by: 'name', ascending: true } },
    ['name'],
    { minMatchCharLength: 1 }
  );

  const deleteImage = async (image) => {
    handleDelete && (await handleDelete(image.id));

    // remove deleted image from displayed images list
    setImages(items.filter((img) => img.url !== image.url));

    // if we are deleting the selected image,
    // fallback to the fitsr image
    if (image.url === selectedImage.url) {
      const newSelectedImage = items.find((img) => img.url !== image.url);
      setSelectedImage(newSelectedImage);
    }
  };

  // this is called after the handleRename callback. once we are sure that
  // the image is renamed successfully, we want to update image list(localImages)
  // and selectedImage to reflect those changes
  const updateImageList = (image) => {
    setSelectedImage(image);
    setImages((images) =>
      images.map((oldImage) => (oldImage.id === image.id ? image : oldImage))
    );
  };

  const generateHandleUpdate = (hideNameInput) => async (image) => {
    try {
      await handleRename(image);
      updateImageList(image);
      hideNameInput();
    } catch (error) {
      return error?.response?.data;
    }
  };

  // automatically set selecetd image if selectedImage is falsy
  // e.g. after fetching the images for the first time.
  // the selected image will be picked based on the form url value,
  // before it falls back to the first image in list
  useEffect(() => {
    if (items.length && !selectedImage) {
      const newSelectedImage =
        items.find(({ url }) => selectedImageUrl === url) || items[0];
      setSelectedImage(newSelectedImage);
    }
  }, [items, Boolean(selectedImage), setSelectedImage, selectedImageUrl]); //eslint-disable-line

  return {
    deleteImage,
    generateHandleUpdate,
    items,
    search,
    selectedImage,
    setSelectedImage,
    totalCount: images?.length || 0,
  };
};
