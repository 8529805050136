import { useEffect, useState } from 'react';

export const useImageDimensions = (src) => {
  const [imageDimensions, setImageDimensions] = useState({
    width: 0,
    height: 0,
  });

  useEffect(() => {
    if (!src) return;

    const img = new Image();
    img.src = src;

    const handleLoad = () => {
      const width = img.naturalWidth;
      const height = img.naturalHeight;

      setImageDimensions({ width, height });
    };

    img.onload = handleLoad;

    return () => {
      img.onload = null;
    };
  }, [src]);

  return imageDimensions;
};
