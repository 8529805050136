// a mapping for material icons/editor actions
// the keys in this object should correspond with the TOOLBAR actions.
// the values are the names of the corresponding material icons
// if the toolbar value is an object we assume that
// the key will look like this ${key}_${value}, e.g. header_3
export const ICON_NAME = {
  bold: 'format_bold',
  italic: 'format_italic',
  underline: 'format_underlined',
  link: 'insert_link',
  header_3: 'text_fields',
  undo: 'undo',
  redo: 'redo',
  list_ordered: 'format_list_numbered',
  list_bullet: 'format_list_bulleted',
};

const generateShortcutsItem = ({ label, title }) =>
  `<div><span data-balloon-break="" aria-label="${title}" data-balloon-pos="up">${label}</span></div>`;

const generateIcon = ({ type, title }) =>
  `<span data-balloon-pos="up" aria-label="${title}" class="material-symbols-outlined">${ICON_NAME[type]}</span>`;

const TOOLBAR = [
  [{ header: '3' }, 'bold', 'italic', 'underline', 'link'],
  [{ list: 'bullet' }, { list: 'ordered' }],
  ['shortcuts', 'undo', 'redo'],
];

const flattenArray = (arr) => {
  const flattened = [];
  for (let i = 0; i < arr.length; i++) {
    if (Array.isArray(arr[i])) {
      flattened.push(...flattenArray(arr[i]));
    } else {
      flattened.push(arr[i]);
    }
  }
  return flattened;
};

// register material icons for the quill toolbar
export const generateIcons = ({ Quill, translate, toolbar = TOOLBAR }) => {
  const icons = Quill.import('ui/icons');
  const flattenedToolbar = flattenArray(toolbar);

  flattenedToolbar.forEach((item) => {
    if (item === 'shortcuts') {
      const title = translate(`.${item}.tooltip`);
      const label = translate(`.${item}.label`);
      icons[item] = generateShortcutsItem({ label, title });
    } else if (typeof item === 'string') {
      const title = translate(`.${item}`);
      icons[item] = generateIcon({ type: item, title });
    } else {
      const [key, value] = Object.entries(item)[0];
      const iconName = `${key}_${value}`;
      const title = translate(`.${iconName}`);
      icons[key][value] = generateIcon({ type: iconName, title });
    }
  });
};

// remove color styling from the pasted text
const attributesToDelete = ['color', 'background'];
const sanitizeClipboardMatcher = (node, delta) => {
  delta.ops = delta.ops.map((op) => {
    const attributes = { ...op.attributes };
    attributesToDelete.forEach((key) => delete attributes[key]);
    return {
      ...op,
      attributes,
    };
  });
  return delta;
};

// to be able to access quills `this`,
// we musn't use arrow functions
function headerShortcutHandler(range) {
  const formatValue = !this.quill.getFormat(range.index).header ? '3' : false;
  this.quill.format('header', formatValue);
}

function undoChange() {
  this.quill.history.undo();
}
function redoChange() {
  this.quill.history.redo();
}
// this const will be used as the modules prop for the
// react-quill component
export const generateModules = (Quill) => ({
  toolbar: {
    container: TOOLBAR,
    handlers: {
      undo: undoChange,
      redo: redoChange,
      shortcuts: () => {}, // suppressing the warnings
    },
  },
  history: {
    delay: 500,
    maxStack: 100,
    userOnly: true,
  },
  clipboard: {
    // if true, quill would try to 'refactor' the html which leads us
    // to risk of falling into infinite loop given our lineBreakMatcher
    // and parse prop on EditorField component.
    // related to the issue https://github.com/gohiring/mpt/issues/1858
    // exapmle of problematic html:
    //  1. <h3>Title</h3><p><br /></p><h2>Subtitle</h2>
    //  2. <h3>Title</h3><p> <br /> </p><h2>Subtitle</h2>
    //  3. <h3>Title</h3><p>Text<br /></p><h2>Subtitle</h2>
    //  these would cause an infinite loop
    matchVisual: false,
    matchers: [
      ['BR', lineBreakMatcher],
      [Node.ELEMENT_NODE, sanitizeClipboardMatcher],
    ],
  },
  keyboard: {
    bindings: {
      linebreak: {
        key: 13,
        shiftKey: true,
        handler: function (range) {
          const [currentLeaf] = this.quill.getLeaf(range.index);
          const [nextLeaf] = this.quill.getLeaf(range.index + 1);

          // add 'break' blot which we defined inside the setup func
          this.quill.insertEmbed(
            range.index,
            SOFT_BREAK,
            true,
            Quill.sources.USER
          );

          // Insert a second break if:
          // At the end of the text area, OR next leaf has a different parent.
          if (nextLeaf === null || currentLeaf.parent !== nextLeaf.parent) {
            this.quill.insertEmbed(
              range.index,
              SOFT_BREAK,
              true,
              Quill.sources.USER
            );
          }

          // move the cursor forward
          this.quill.setSelection(range.index + 1, Quill.sources.SILENT);
        },
      },
      // ctrl + H => text to heading
      headerShortcut: {
        key: 'H',
        shortKey: true,
        handler: headerShortcutHandler,
      },
      // ctrl + Y => redo
      redoShortcut: {
        key: 'Y',
        shortKey: true,
        handler: redoChange,
      },
    },
  },
});

// translations for internal quill components
export const generateTranslations = ({ translate }) => ({
  linkLabel: translate('.addLinkLabel'),
  visitLink: translate('.visitLink'),
  linkSave: translate('app.actions.save'),
  linkEdit: translate('app.actions.edit'),
  linkRemove: translate('app.actions.delete'),
});

const lineBreakMatcher = (node, delta) => {
  // a quick-fix for https://github.com/gohiring/mpt/issues/1853
  // adding a white space before the line break prevent the quill from crashing
  // examples of problematic html:
  // 1. <h3>Kontakt</h3><p><br /><strong>Not<br />ok</strong></p>
  // 2. <h3>Kontakt</h3><p><br /><em>This is still<br />not ok</em></p>
  delta.ops = [{ insert: ' ' }, { insert: { [SOFT_BREAK]: '' } }];
  return delta;
};

const SOFT_BREAK = 'SoftBreak';

// allowed values for the indent (margin-left)
// strings from "0em" to "9em"
const ALLOWED_INDENTS = Array(10)
  .fill()
  .map((_, index) => index + 'em');

export const setup = (Quill) => {
  const Break = Quill.import('blots/break');
  const Embed = Quill.import('blots/embed');
  const Parchment = Quill.import('parchment');

  // adds inline styles to the indented items.
  // the default behavior is to add the class instead.
  // that does not work for us because quill's style is not available
  // in job boards
  // TODO (nikola 13.12.2023): this is a temporary hack that does not solve the issue
  // with the nested lists(which are still not supported),
  // but mimics the appearance of the nested lists to a certain point
  class IndentAttributor extends Parchment.Attributor.Style {
    add(node, value) {
      // if there are some styling that would clash with ours,
      // do not override it.
      if (node.style.marginLeft && !node.style.marginLeft.includes('em')) {
        return false;
        // if the value indicates the in/decrement calculate new value
      } else if (typeof value === 'string' && /^[+-]1$/.test(value)) {
        return super.add(node, `${calculateIndentMargine({ node, value })}em`);

        // if the value is given explicitly just apply the appropriate style
        // could be number that matches the index of allowed value, or the value itself
      } else {
        const normalizedValue =
          typeof value === 'number' ? `${value}em` : value;
        return super.add(node, normalizedValue);
      }
    }
  }

  const IndentStyle = new IndentAttributor('indent', 'margin-left', {
    scope: Parchment.Scope.BLOCK,
    whitelist: ALLOWED_INDENTS,
  });
  Quill.register(IndentStyle, true);

  class SoftBreak extends Break {
    length() {
      return 1;
    }
    value() {
      return '\n';
    }

    insertInto(parent, ref) {
      Embed.prototype.insertInto.call(this, parent, ref);
    }
  }
  SoftBreak.blotName = SOFT_BREAK;
  SoftBreak.tagName = 'BR';
  Quill.register(SoftBreak);
};

// value could be either the string '-1' or '+1'
const calculateIndentMargine = ({ node, value }) => {
  const step = Number(value);
  if (!node.style.marginLeft) {
    return step;
  } else {
    const [currentIndent] = node.style.marginLeft.split('em');
    return Number(currentIndent) + step;
  }
};
