import { useState } from 'react';

export const useGenerateImageUploadValues = ({
  handleUpload,
  setFile,
  setFileError,
  handleSelect,
  uploadErrorMsg,
  close,
  file,
  successMsg,
}) => {
  const [uploadError, setUploadError] = useState(null);
  const [uploading, setUploading] = useState(false);
  const [requestController, setRequestController] = useState(null);
  const [progress, setProgress] = useState(0);
  const reset = () => {
    setProgress(0);
    setUploading(false);
    setFileError(null);
    setUploadError(null);
    setFile(null);
  };

  const onUpload = ({ url } = {}) => {
    reset();
    handleSelect({ url, name: file.name });
    Flash.success(successMsg);
    close();
  };

  const onUploadProgress = (event) => {
    setProgress(Math.round((100 * event.loaded) / event.total));
  };

  const onError = () => {
    setUploadError(uploadErrorMsg);
    setUploading(false);
  };

  const upload = async () => {
    setUploading(true);
    const abortController = new AbortController();
    setRequestController(abortController);
    const signal = abortController.signal;

    await handleUpload({
      file,
      onUploadProgress,
      onSuccess: onUpload,
      signal,
      onError,
    });
  };

  const onCancel = () => {
    setProgress(0);
    setUploading(false);
    requestController.abort();
  };

  return {
    uploadError,
    uploading,
    reset,
    progress,
    upload,
    onCancel,
  };
};
