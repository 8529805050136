import styled from 'styled-components';
import { Flex } from '../../styles/flex';
import {
  backgroundBlue,
  borderLightGray,
  gray,
  lightBlue,
} from '../../styles/variables';

export const BrowseButtonContainer = styled.div`
  margin: 16px 0;
`;

export const ImageUploadContainer = styled.div`
  align-items: center;
  background: none;
  border: 2px dotted ${borderLightGray};
  color: ${gray};
  display: flex;
  height: 100%;
  justify-content: center;
  padding: 24px 30px;
  text-align: center;
  flex-grow: 1;
`;

export const ButtonsWrapper = styled.div`
  display: flex;
  gap: 10px;
  margin-top: auto;
  justify-content: end;
`;

export const ContainerWithSelectedImage = styled.div`
  display: flex;
  min-height: 150px;
  height: 100%;
  flex-grow: 1;
  & > * {
    flex: 1;
    flex-grow: 1;
  }
  img {
    width: max-content;
  }
`;

export const NoImageButton = styled.button`
  border: none;
  background: transparent;
  min-width: 100px;
  .select-image {
    display: none;
  }
  &:hover {
    .select-image {
      display: block;
    }
    .no-image {
      display: none;
    }
    *,
    span.material-symbols-outlined {
      color: ${lightBlue};
    }
  }
`;

export const ActionButton = styled.button`
  display: flex;
  align-items: center;
  padding: 12px;
  height: fit-content;
  &:first-child {
    margin-left: 8px;
  }
`;

export const ImageContainer = styled.div`
  align-items: center;
  justify-content: center;
  width: 100%;
  display: flex;
  overflow: hidden;
  min-height: ${({ imageHeight }) => imageHeight || 150}px;
  img {
    max-width: 100%;
    height: ${({ imageHeight }) => (imageHeight ? `${imageHeight}px` : 'auto')};
    margin-bottom: 8px;
  }
  .material-symbols-outlined {
    font-size: 24px;
    background-color: #fff;
    color: #bbb;
    border-radius: 3px;
    margin: 5px;
  }
`;

const activeRowStyle = `
  background: ${lightBlue};
  color: white;
`;

export const StyledImageRow = styled(Flex)`
  width: 100%;
  min-height: 40px;
  justify-content: space-between;
  align-items: center;
  border-radius: 3px;
  padding: 4px;
  cursor: pointer;
  ${({ active }) => (active ? activeRowStyle : '')}
  // buttons are invisible by default
  & > div > button {
    display: none;
  }
  // we show them on hover
  &:hover {
    & > div > button {
      display: inherit;
    }
    ${({ active }) => (!active ? `background: ${backgroundBlue};` : '')}
  }
  form {
    width: 100%;
  }
`;

export const RenameInputWrapper = styled(Flex)`
  background: ${backgroundBlue};
  border-bottom: 2px solid ${borderLightGray};
  button {
    display: block !important;
  }
  input {
    border: none;
    background: transparent;
    outline: none;
    flex-grow: 2;
  }
`;
