import React from 'react';
import useI18n from '../../../../../lib/use_i18n';
import { ProgressBar } from '../../../progress_bar';
import { Flex } from '../../../styles/flex';
import { ErrorWrapper } from '../../../styles/form';
import { gray } from '../../../styles/variables';
import { ButtonsWrapper, ContainerWithSelectedImage } from '../styles';
import { humanFileSize } from '../utils';
import { useGenerateImageUploadValues } from './use_generate_image_upload_values';

export const ImageUpload = ({
  file,
  handleUpload,
  setFile,
  handleSelect,
  uploadErrorMsg,
  fileError,
  setFileError,
  successMsg,
  close,
}) => {
  const { translate } = useI18n('image_uploader');

  const { uploadError, uploading, reset, progress, upload, onCancel } =
    useGenerateImageUploadValues({
      setFile,
      setFileError,
      handleSelect,
      handleUpload,
      uploadErrorMsg,
      file,
      close,
      successMsg,
    });
  return (
    <ContainerWithSelectedImage>
      <Flex
        overflowY="auto"
        flexDirection="column"
        padding="16px"
        marginBottom="56px"
      >
        {!fileError && (
          <img
            src={file.preview}
            className="img-thumbnail"
            data-testid="image-preview"
            alt={file.name}
          />
        )}
        <strong>{file.name}</strong>
        <Flex color={gray}>{humanFileSize(file.size, 'kb')} KB</Flex>

        {(fileError || uploadError) && (
          <ErrorWrapper>{fileError || uploadError}</ErrorWrapper>
        )}

        {!uploading ? (
          <Flex gap="10px" position="absolute" bottom="16px" right="16px">
            <button type="button" className="btn btn-default" onClick={reset}>
              {translate('.buttons.discard')}
            </button>
            <button
              type="button"
              className="btn btn-primary"
              disabled={fileError}
              onClick={upload}
            >
              {translate('.buttons.upload')}
            </button>
          </Flex>
        ) : (
          <Uploading progress={progress} onCancel={onCancel} />
        )}
      </Flex>
    </ContainerWithSelectedImage>
  );
};

const Uploading = ({ progress, onCancel }) => {
  const { translate } = useI18n('image_uploader');
  return (
    <div>
      <Flex alignItems="center" gap={'16px'}>
        <ProgressBar percentage={progress} />
        <div className="progress-label">{progress}%</div>
      </Flex>

      <ButtonsWrapper>
        <button
          type="button"
          className="btn btn-default"
          data-testid="cancel-button"
          onClick={onCancel}
        >
          {translate('.buttons.cancel')}
        </button>
      </ButtonsWrapper>
    </div>
  );
};
