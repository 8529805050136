import ReactQuill from 'react-quill';
import styled, { css, keyframes } from 'styled-components';
import { InputWrapper } from '../form/input_wrapper';
import { disabledStyle } from './form';
import {
  borderButtonLightGray,
  borderLightGray,
  fontSize,
  lightBlue,
  lightGray,
  red,
} from './variables';

// because oveflow would be visible before the transition ends
// creating visual bug, if we rely on transition only
const heightTransition = keyframes`
  from {
    height: 0;
    overflow: hidden;
  }
  to {
    height: 32px; /* Adjust to your desired height */
    overflow: visible;
  }
`;
const animationDuration = '0.3s';

// overriding the quill snow theme to match our own.
export const StyledQuill = styled(ReactQuill)`
  ${({ disabled }) => (disabled ? disabledStyle : '')}
  ${({ hide }) => (hide ? 'display: none;' : '')}
  .ql-toolbar.ql-snow {
    display: flex;
    border: none;
    padding: 0;
    overflow: ${({ isExpanded }) => (!isExpanded ? 'hidden' : 'visible')};
    transition: height 0.3s ease;
    height: ${({ isExpanded, disabled }) =>
      isExpanded && !disabled ? '32px' : '0'};
    animation: ${({ isExpanded }) =>
      isExpanded
        ? css`
            ${heightTransition} ${animationDuration} ease
          `
        : 'none'};
    button {
      border: 1px solid ${borderButtonLightGray};
      height: 32px;
      width: 32px;
      padding: 0;
      span {
        display: flex;
        align-items: center;
        justify-content: center;
        height: 32px;
        width: 32px;
      }
    }
    button:first-child {
      border-top-left-radius: 3px;
      border-bottom-left-radius: 3px;
    }
    button:not(:first-child) {
      border-left: none;
    }
    button:last-child {
      border-top-right-radius: 3px;
      border-bottsdsaom-right-radius: 3px;
    }
    .ql-formats:last-child {
      margin-left: auto;
      margin-right: 0;
    }

    // shortcuts label styling
    .ql-shortcuts {
      border-top: none;
      border-left: none;
      border-bottom: none;
      padding-right: 16px;
      width: auto;
      span {
        color: ${lightBlue};
        width: auto;
        &:after {
          text-align: left;
        }
      }
    }
  }
  .ql-toolbar.ql-snow + .ql-container.ql-snow {
    margin-top: 8px;
    border-radius: 3px;
    border: 1px solid ${borderLightGray};
    ${({ isActive }) =>
      isActive ? `box-shadow: inset 0 -2px 0 ${lightBlue};` : ''};
  }
  .ql-tooltip {
    z-index: 2000;
  }
  .ql-editor {
    font-size: ${fontSize};
    h3,
    h2 {
      font-size: 18px;
      line-height: 1.9;
    }
    p {
      margin-bottom: 10.5px; //to match the StepStone
    }
  }

  // quill uses css for localization, quite unfortunate,
  // luckily we only need them for the link popoup. We should probably
  // consider making it a custom component if this proves insufficient
  .ql-snow .ql-tooltip::before {
    content: '${({ translations }) => translations?.linkVisit}';
  }
  .ql-snow .ql-tooltip .ql-action::after {
    content: '${({ translations }) => translations?.linkEdit}';
  }
  .ql-snow .ql-tooltip .ql-remove::before {
    content: '${({ translations }) => translations?.linkRemove}';
  }
  .ql-snow .ql-tooltip[data-mode='link']::before {
    content: '${({ translations }) => translations?.linkLabel}';
  }
  .ql-snow .ql-tooltip[data-mode='link'] .ql-action::after {
    content: '${({ translations }) => translations?.linkSave}';
  }
`;

export const PlaceholderQuill = styled(StyledQuill)`
  .ql-toolbar.ql-snow {
    display: none;
  }
  * {
    color: ${lightGray};
    opacity: 0.9;
  }
`;

export const StyledInputWrapper = styled(InputWrapper)`
  &.has-error .ql-toolbar.ql-snow + .ql-container.ql-snow {
    box-shadow: inset 0 -2px 0 ${red};
  }
`;
