import { useEffect, useMemo, useState } from 'react';

// A custom hook that receives an element and an optional
// parent element (defaults to body) and returns a boolean
// value based on whether the element in question
// is visible inside the said element.
// This information is useful if we want to prevent unnecessary
// image or complex component renders.
export const useIsVisible = (elRef, containerRef) => {
  const [isIntersecting, setIntersecting] = useState(false);

  const observer = useMemo(
    () =>
      new IntersectionObserver(
        ([entry]) => setIntersecting(entry.isIntersecting),
        { root: containerRef?.current }
      ),
    [elRef, containerRef] //eslint-disable-line
  );

  useEffect(() => {
    observer.observe(elRef.current);
    return () => observer.disconnect();
  }, []); //eslint-disable-line

  return isIntersecting;
};
