import React from 'react';
import useI18n from '../../../../../lib/use_i18n';
import Icon from '../../../icon';
import { BrowseButtonContainer, ImageUploadContainer } from '../styles';
import { humanFileSize } from '../utils';

export const DropZone = ({
  validFileFormats,
  formatHint,
  getInputProps,
  getRootProps,
  maxFileSize,
  open,
}) => {
  const { translate } = useI18n('image_uploader');
  return (
    <ImageUploadContainer {...getRootProps()}>
      <input
        {...getInputProps()}
        name="image_upload_modal_input"
        data-testid="drop-input"
      />

      <div>
        <Icon name="image" size={24} />

        <div>
          {translate('.format_and_size', {
            size: humanFileSize(maxFileSize, 'mb'),
          })}
        </div>
        <div>{formatHint}</div>
        <div>
          {validFileFormats
            ?.map((fileExtension) => fileExtension.toUpperCase())
            .join(', ')}
        </div>

        <BrowseButtonContainer>
          <button type="button" className="btn btn-default" onClick={open}>
            {translate('.buttons.browse')}
          </button>
        </BrowseButtonContainer>
        <div>{translate('.drag_and_drop')}</div>
      </div>
    </ImageUploadContainer>
  );
};
